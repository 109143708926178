// Constants
import { PACKAGES, ROLES, URL_APP_MENU } from '@/constants'
// Components
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Mixins
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuex
import { mapActions, mapMutations, mapGetters } from 'vuex'
// Services
import { createMenuByData } from '@/services/place'
// Utils
import { get } from 'lodash'
import { getEnvironmentVariable } from '@/utils'
const uniqid = require('uniqid')

export default {
  name: 'UserFirstAccess',
  components: { VuetifyToolBar },
  mixins: [intercomMixin, uiMixin],
  data() {
    return {
      processingRequest: false
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('place', ['placeData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    ...mapMutations('app', ['setShowQrAlert']),
    ...mapActions('authentication', ['updateUser']),
    /**
     * Show alert with error
     *
     * @param {string} error - error message
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Evento lanzado cuando hacemos click sobre el botón "Acceder a la aplicación"
     */
    async handleClickAccessButton() {
      try {
        // Loading
        this.processingRequest = true
        // Actualizamos estado del usuario (firstAccess)
        await this.updateUser({
          id: this.userData.id,
          firstAccess: false
        })

        // Modificamos variable de Intercom
        this.intercomUpdate({ bakarta_first_access: 'skip' })

        // Mostramos mensaje del QR
        this.setShowQrAlert(true)
        // Redirigimos a la guía
        this.routerPushTo({
          name: 'plansGuide'
        })
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Evento lanzado cuando hacemos click sobre el botón "Iniciar tutorial"
     */
    async handleClickLaunchOnBoardingButton() {
      try {
        // Loading
        this.processingRequest = true
        // Actualizamos estado del usuario (firstAccess)
        await this.updateUser({
          id: this.userData.id,
          firstAccess: false,
          onboarding: {
            category: false,
            menu: false,
            product: false
          }
        })

        // Modificamos variable de Intercom
        this.intercomUpdate({
          bakarta_first_access: 'onboarding',
          bakarta_onboarding_menu: false,
          bakarta_onboarding_category: false,
          bakarta_onboarding_dish: false,
          bakarta_onboarding_finish: false
        })

        // Redirigimos al onboarding
        this.routerPushTo({
          name: 'onboardingMenu'
        })
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Evento lanzado cuando hacemos click sobre el botón "Crear demo"
     */
    async handleClickMakeDemoButton() {
      try {
        // Loading
        this.processingRequest = true
        // Creamos menu demo
        const menuId = uniqid()
        const categoryId1 = uniqid()
        const categoryId2 = uniqid()
        const categoryId3 = uniqid()
        const productId1 = uniqid()
        const productId2 = uniqid()
        const productId3 = uniqid()
        const productId4 = uniqid()
        const productId5 = uniqid()
        const productId6 = uniqid()
        const productId7 = uniqid()
        const productId8 = uniqid()
        const productId9 = uniqid()

        const { ok } = await createMenuByData(this.placeData.id, {
          menus: [
            {
              id: menuId,
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              description: 'carta de demostración',
              name: 'Carta',
              order: 0,
              parentId: null,
              places: [this.placeData.id],
              prices: null
            }
          ],
          categories: [
            {
              id: categoryId1,
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              name: 'Para compartir',
              order: 0,
              parentId: menuId
            },
            {
              id: categoryId2,
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              description: null,
              name: 'Entrantes',
              order: 1,
              parentId: menuId
            },
            {
              id: categoryId3,
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              description: null,
              name: 'Carnes',
              order: 2,
              parentId: menuId
            }
          ],
          dishes: [
            {
              id: productId1,
              allergens: [1, 2],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId1],
              description: 'Doradas y fritas con el mejor aceite.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product1.jpg',
              name: 'Patatas fritas',
              order: 0,
              prices: {
                [categoryId1]: {
                  0: {
                    order: 0,
                    price: 2.5
                  }
                }
              }
            },
            {
              id: productId2,
              allergens: [3, 4],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId1],
              description: 'Un placer redondo en tu paladar.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product2.jpg',
              name: 'Aros de cebolla',
              order: 1,
              prices: {
                [categoryId1]: {
                  0: {
                    order: 0,
                    price: 3.5
                  }
                }
              }
            },
            {
              id: productId3,
              allergens: [4, 6],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId1],
              description: 'Un placer que se come mejor en pareja.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product3.jpg',
              name: 'Nachos con queso',
              order: 2,
              prices: {
                [categoryId1]: {
                  0: {
                    order: 0,
                    price: 5.5
                  }
                }
              }
            },
            {
              id: productId4,
              allergens: [1, 2, 5, 7],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId2],
              description: 'Todo lo saludable y sabroso del queso en una ensalada.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product4.jpg',
              name: 'Ensalada de queso de cabra',
              order: 0,
              prices: {
                [categoryId2]: {
                  0: {
                    order: 0,
                    price: 5.75
                  }
                }
              }
            },
            {
              id: productId5,
              allergens: [7, 8],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId2],
              description: 'Para los días frios, nada mejor que una buena sopa.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product5.jpg',
              name: 'Sopa de cebolla',
              order: 1,
              prices: {
                [categoryId2]: {
                  0: {
                    order: 0,
                    price: 3.5
                  }
                }
              }
            },
            {
              id: productId6,
              allergens: [2, 5],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId2],
              description: 'Finas lonchas de ternera, acompañada de parmesano.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product6.jpg',
              name: 'Carpaccio',
              order: 2,
              prices: {
                [categoryId2]: {
                  0: {
                    order: 0,
                    price: 6.25
                  }
                }
              }
            },
            {
              id: productId7,
              allergens: [2, 5],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId3],
              description: 'Elaboración casera con las mejores carnes.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product7.jpg',
              name: 'Hamburguesa de ternera',
              order: 0,
              prices: {
                [categoryId3]: {
                  0: {
                    order: 0,
                    price: 12.25
                  }
                }
              }
            },
            {
              id: productId8,
              allergens: [3, 6, 7],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId3],
              description: 'Natural del mediterraneo y fresca del día.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product8.jpg',
              name: 'Dorada a la sal',
              order: 1,
              prices: {
                [categoryId3]: {
                  0: {
                    order: 0,
                    price: 11
                  }
                }
              }
            },
            {
              id: productId9,
              allergens: [1, 7],
              brand: getEnvironmentVariable('VUE_APP_BRAND'),
              categories: [categoryId3],
              description: 'Elaboración propia, con los mejores ingredientes.',
              disabled: false,
              image: 'https://app.bakarta.com/img/demo/product9.jpg',
              name: 'Pasta a la carbonara',
              order: 2,
              prices: {
                [categoryId3]: {
                  0: {
                    order: 0,
                    price: 8.5
                  }
                }
              }
            }
          ]
        })

        if (!ok) {
          throw new Error('No se pudo generar correctamente la carta demo.')
        }

        // Actualizamos estado del usuario (firstAccess)
        await this.updateUser({
          id: this.userData.id,
          firstAccess: false
        })

        // Modificamos variable de Intercom
        this.intercomUpdate({ bakarta_first_access: 'demo' })

        // Abrimos nueva pestaña con enlace al menú
        window.open(`${URL_APP_MENU}${this.placeData.id}`, '_blank')
        // Mostramos mensaje del QR
        this.setShowQrAlert(true)
        // Redirigimos al menú
        this.routerPushTo({
          name: 'menus'
        })
      } catch (error) {
        this.handleError(error.message)
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      // Inicializamos intercom
      await this.initializeIntercom({
        bakarta_plan: PACKAGES.free.value
      })
      this.intercomTrackEvent('lastLogin')

      // Si el usuario ya pasó por aquí "firstAccess",
      // lo redirigimos a la raíz
      if (!get(this.userData, 'firstAccess', false)) {
        // Redirigimos al inicio de la aplicación
        this.routerPushTo(get(ROLES, `${this.userData.role}.urlHome`, '/'))
      }
    }
  }
}
